import React from "react";

import "_style.scss";
import "_reset.scss";
import "_renewal.scss";

import Renewal from "./Renewal";

const App = () => {
  return (
    <div className="wrap">
      <Renewal />
    </div>
  );
};

export default App;