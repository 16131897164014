import React from "react";

import logo from "./img/logo_vinstilla.svg";
import background from "./img/background.jpg";

const Renewal = () => {
    return (
        <section className="renewal">
            <div className="logo">
                <img src={logo} alt="background" />
            </div>

            <h3 className="copy-heading">
                Coming
            </h3>

            <div className="background">
                <div className="overlay"></div>
                <img src={background} alt="background" />
            </div>
        </section>
    )   
   }
   
   export default Renewal;